import React, { useState } from "react";
import { Helmet } from "react-helmet";
import "./App.css";
import Header from "./components/Header";
import { BrowserRouter } from "react-router-dom";
import Router from "router";
import Footer from "components/Footer";

function App() {
  return (
    <BrowserRouter>
      <Helmet>
        <title>Hotel Yalana</title>
        <meta name="description" content="Hotel Yalana, Shillong" />
      </Helmet>
      <div className="px-2">
        <Header />
        <Router />
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
