import React from "react";

type ReviewCardProps = {
  photo: string;
  name: string;
  review: string;
  rating: number;
};

const ReviewCard: React.FC<ReviewCardProps> = ({
  photo,
  name,
  review,
  rating,
}) => {
  return (
    <ReviewCards name={name} review={review} rating={rating} url={photo} />
  );
};

export default ReviewCard;
const ReviewCards = ({
  url,
  name,
  review,
  rating,
}: {
  url: string;
  name: string;
  review: string;
  rating: number;
}) => {
  return (
    <div className="pt-8" id="reviews">
      <div className="px-6 mx-auto max-w-7xl md:px-12 xl:px-6">
        <div className="gap-8 space-y-8">
          <div className="p-8 bg-white border border-gray-100 shadow-2xl aspect-auto rounded-3xl dark:bg-gray-800 dark:border-gray-700 shadow-gray-600/10 dark:shadow-none">
            <div className="flex items-center gap-4">
              <img
                className="w-12 h-12 rounded-full"
                src={url}
                alt={name}
                width="400"
                height="400"
                loading="lazy"
              />
              <div>
                <h6 className="text-lg font-medium text-gray-700 capitalize dark:text-white">
                  {name}
                </h6>
              </div>
            </div>
            <p className="mt-8">{review}</p>
            <div className="flex justify-center mt-4">
              {[...Array(rating)].map((star, index) => (
                <svg
                  key={index}
                  className="w-6 h-6 text-yellow-500"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M12 17.27l6.18 3.73-1.64-7.19L21 9.24l-7.19-.61L12 2 10.19 8.63 3 9.24l5.46 4.57L6.82 21z" />
                </svg>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
