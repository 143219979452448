import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "components/ui/form";

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "components/ui/select";
import React from "react";
import { SubmitHandler, UseFormReturn } from "react-hook-form";
import { FormFieldType } from "./types";
import { cn } from "lib/utils";
import { Input } from "components/ui/input";
import { DialogFooter } from "components/ui/dialog";
import { Button } from "components/ui/button";
import { twMerge } from "tailwind-merge";
import { Popover, PopoverContent, PopoverTrigger } from "components/ui/popover";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { Calendar } from "components/ui/calendar";
import { RadioGroup, RadioGroupItem } from "./ui/radio-group";

type OptionsT = {
  label: string;
  value: string | number | boolean;
};
type FormProps<T> = {
  form: UseFormReturn<T | any>;
  defaultValue?: T | any;
  loading: boolean;
  inputFields: FormFieldType[];
  className?: string;
};

export const YalanaForm = <T,>({
  form,
  defaultValue,
  loading = false,
  inputFields,
  className,
}: FormProps<T>) => {
  const defaultStyles =
    "grid w-full grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-2 md:gap-4";
  const style = twMerge(defaultStyles, className);
  return (
    <div className={style}>
      {!loading ? (
        <React.Fragment>
          {inputFields.map((input: any, i: number) => (
            <div key={i} className="w-full">
              <FormField
                shouldUnregister
                name={input.name}
                control={form.control}
                defaultValue={defaultValue?.[input.name]}
                render={({ field }) => (
                  <div className="w-full">
                    {input.select ? (
                      <FormItem className="w-full">
                        <FormLabel>
                          {input.label}{" "}
                          {input.required && (
                            <span className="text-red-500">*</span>
                          )}
                        </FormLabel>
                        <Select
                          onValueChange={field.onChange}
                          defaultValue={defaultValue?.[input.name]}
                          value={field.value}
                        >
                          <FormControl className="w-full">
                            <SelectTrigger>
                              <SelectValue
                                className="capitalize"
                                placeholder={`Please select ${input.label.toLowerCase()}`}
                              />
                            </SelectTrigger>
                          </FormControl>
                          <SelectContent>
                            {input.options?.map(
                              (option: OptionsT, i: number) => (
                                <React.Fragment key={i}>
                                  <SelectItem
                                    className="capitalize"
                                    value={option.value as string}
                                  >
                                    {option.label}
                                  </SelectItem>
                                </React.Fragment>
                              )
                            )}
                          </SelectContent>
                          <FormMessage />
                          <FormDescription>{input.helperText}</FormDescription>
                        </Select>
                      </FormItem>
                    ) : input.radio ? (
                      <FormItem className="w-full">
                        <FormLabel>
                          {input.label}{" "}
                          {input.required && (
                            <span className="text-red-500">*</span>
                          )}
                        </FormLabel>
                        <RadioGroup
                          defaultValue={field.value}
                          onValueChange={field.onChange}
                          value={field.value}
                          className={cn(
                            input.horizontal ? "flex space-x-4" : "space-y-2"
                          )}
                        >
                          {input.options?.map(
                            (option: OptionsT, idx: number) => (
                              <div
                                key={idx}
                                className="flex items-center space-x-2"
                              >
                                <RadioGroupItem
                                  value={option.value as string}
                                  id={`${input.name}-${idx}`}
                                />
                                <FormLabel>{option.label}</FormLabel>
                              </div>
                            )
                          )}
                        </RadioGroup>
                        <FormMessage />
                      </FormItem>
                    ) : (
                      <React.Fragment key={i}>
                        {input.type === "date" ? (
                          <FormItem className="flex flex-col">
                            <FormLabel>
                              {input.label}
                              {input.required && (
                                <span className="text-red-500">*</span>
                              )}
                            </FormLabel>
                            <Popover>
                              <PopoverTrigger asChild>
                                <FormControl>
                                  <Button
                                    variant={"outline"}
                                    className={cn(
                                      "w-full pl-3 text-left font-normal",
                                      !field.value && "text-muted-foreground"
                                    )}
                                  >
                                    {field.value ? (
                                      <span>
                                        {new Date(
                                          field.value
                                        ).toLocaleDateString()}
                                      </span>
                                    ) : (
                                      "Departure" // <span>Pick a date</span>
                                    )}
                                    <FontAwesomeIcon
                                      icon={faCalendarAlt}
                                      className="w-4 h-4 ml-auto opacity-50"
                                    />
                                  </Button>
                                </FormControl>
                              </PopoverTrigger>
                              <PopoverContent
                                className="w-auto p-0"
                                align="start"
                              >
                                <Calendar
                                  mode="single"
                                  selected={field.value}
                                  onSelect={field.onChange}
                                  // @ts-ignore
                                  disabled={(date) => date < new Date()}
                                  initialFocus
                                />
                              </PopoverContent>
                            </Popover>
                            <FormMessage />
                          </FormItem>
                        ) : input.type === "number" ? (
                          <FormItem>
                            <FormLabel>{input.label}</FormLabel>
                            <FormControl>
                              <Input
                                type="number"
                                className="w-full"
                                {...field}
                                pattern="[0-9]*"
                                placeholder={`Please enter ${input.label.toLowerCase()}`}
                                onChange={(e) =>
                                  field.onChange(parseInt(e.target.value))
                                }
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        ) : (
                          <FormItem>
                            <FormLabel>
                              {input.label}{" "}
                              {input.required && (
                                <span className="text-red-500">*</span>
                              )}
                            </FormLabel>
                            <Input
                              {...field}
                              className="w-full"
                              placeholder={
                                "Please enter " + input.label.toLowerCase()
                              }
                              type={input.type}
                            />
                            <FormDescription>
                              {input.helperText}
                            </FormDescription>
                            <FormMessage />
                          </FormItem>
                        )}
                      </React.Fragment>
                    )}
                  </div>
                )}
              />
            </div>
          ))}
        </React.Fragment>
      ) : (
        <>
          {inputFields.map((input, i) => (
            <>
              <div
                key={i}
                className="w-full h-12 bg-gray-200 rounded-lg col-span-auto animate-pulse"
              />
            </>
          ))}
        </>
      )}
    </div>
  );
};

type FormTagProps<T> = {
  form: UseFormReturn<T | any>;
  children: React.ReactNode;
  onSubmit: SubmitHandler<T | any>;
  isLoading?: boolean;
  className?: string;
  buttonTitle?: string;
  buttonStyle?: string;
};

export const FormTag = <T,>({
  form,
  children,
  onSubmit,
  className,
  isLoading = false,
  buttonTitle = "Submit",
  buttonStyle,
}: FormTagProps<T>) => {
  const defaultStyles = "space-y-4 p-1";
  const styles = cn(defaultStyles, className);
  const btnStyle = cn("w-11/12 uppercase tracking-widest", buttonStyle);
  return (
    <Form {...form}>
      <form
        noValidate
        onSubmit={form.handleSubmit(onSubmit)}
        className={styles}
      >
        {children}
        <DialogFooter className="py-2 sm:pt-2 md:pt-3 lg:pt-4">
          <Button
            className={btnStyle}
            variant={"default"}
            size={"lg"}
            disabled={!form.formState.isDirty || isLoading}
            type={"submit"}
          >
            {isLoading ? "Loading" : buttonTitle}
          </Button>
        </DialogFooter>
      </form>
    </Form>
  );
};
