import ContactSection from "./ContactSection";
import Facilities from "./Facilities";

import RoomList from "./RoomList";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import HeroSection from "./HeroSection";

import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import React, { ReactNode, useEffect } from "react";
import Review from "./Review";
interface FadeInSectionProps {
  children: ReactNode;
}

const FadeInSection: React.FC<FadeInSectionProps> = ({ children }) => {
  const controls = useAnimation();
  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={{
        visible: { opacity: 1 },
        hidden: { opacity: 0 },
      }}
      transition={{ duration: 2.5 }}
    >
      {children}
    </motion.div>
  );
};

const Home: React.FC = () => {
  return (
    <div className="w-full 2xl:container">
      <FadeInSection>
        <HeroSection />
      </FadeInSection>
      <FadeInSection>
        <RoomList />
      </FadeInSection>
      <FadeInSection>
        <Facilities />
      </FadeInSection>
      <FadeInSection>
        <ContactSection />
      </FadeInSection>
      <FadeInSection>
        <Review />
      </FadeInSection>
    </div>
  );
};
export default Home;
