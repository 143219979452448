import { ReactNode } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface ContactItemProps {
  icon: IconProp;
  front: ReactNode;
  back: ReactNode;
}
export const Icon = ({ icon }: { icon: IconProp }) => {
  return (
    <div
      className="logo-bg w-[40px] md:w-[60px] h-[40px] md:h-[60px] flex items-center justify-center"
      style={{
        backgroundColor: "#3e0909",
        borderRadius: "50%",
        position: "absolute",
        top: "-12%",
        left: "50%",
        transform: "translateX(-50%)",
      }}
    >
      <FontAwesomeIcon icon={icon} className="text-white" />
    </div>
  );
};
const ContactItem: React.FC<ContactItemProps> = ({ icon, front, back }) => {
  return (
    <div className="w-full md:w-1/3 justify-center contactus pb-2 md:pb-10 h-[145px] md:h-[300px]">
      <div className="flip-card">
        <div className="flip-card-inner">
          {/* Front of the card */}
          <div className="flip-card-front">
            <Icon icon={icon} />
            <div className="w-full h-full flex items-center justify-center">
              <div className="text-center">{front}</div>
            </div>
          </div>

          {/* Back of the card */}
          <div className="flip-card-back">
            <Icon icon={icon} />
            <div className="w-full h-full flex items-center justify-center">
              <div className="text-center">{back}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ContactItem;
