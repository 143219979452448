import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationDot,
  faPhone,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";

import logo from "images/logo.png";
import { Button } from "./ui/button";
import { useNavigate } from "react-router-dom";
export default function Footer() {
  const navigate = useNavigate();
  const handleSubmit = () => {
    navigate("/bookroom");
  };
  return (
    <div className="bg-gray-800 text-white md:px-10  py-8">
      <div className="flex justify-between">
        <img src={logo} alt="logo" className="hidden md:block h-[80px]" />
        <div className=" px-2">
          {/* <p id="contact" className="font-DosisMedium text-md md:text-2xl mb-5">
            Hotel Yalana
          </p> */}
          <div className="flex justify-start">
            <Button
              variant="outline"
              onClick={handleSubmit}
              className="w-1/2 font-DosisMedium px-4 mb-4 justify-center text-base text-zinc-800"
              style={{ border: "1px solid rgb(39, 39, 42)" }}
            >
              &nbsp;&nbsp;Book Now
            </Button>
          </div>
          <p className="mb-2 font-InterRegular">
            <FontAwesomeIcon icon={faLocationDot} fontSize="small" />
            &nbsp;Main Road, Laitumkhrah,
            <br /> Shillong - 793 003, Meghalaya
          </p>
          <p className="mb-2 font-InterRegular">
            <FontAwesomeIcon icon={faPhone} fontSize="small" />
            &nbsp;+91 364 2211240 / 2226059/ 9402318930
          </p>
          <p className=" font-InterRegular">
            <FontAwesomeIcon icon={faEnvelope} fontSize="small" />
            &nbsp;yalana.hotel@rediffmail.com
          </p>
        </div>
      </div>
    </div>
  );
}
