import React from "react";
import logo from "images/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { Button } from "./ui/button";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";

export default function Header() {
  const navigate = useNavigate();
  const handleSubmit = () => {
    // window.location.href = "/#bookroom";
    //go to the /bookroom page
    navigate("/bookroom");
  };
  const goHome = () => {
    navigate("/");
  };

  return (
    <>
      <div className="flex items-center pre-header ">
        <p className="pl-10 text-xs md:pl-20">
          <FontAwesomeIcon icon={faLocationDot} /> Main Road, Laitumkhrah,
          Shillong - 793003
        </p>
      </div>
      <nav className="h-16 pl-4 shadow-xs pastel-brown-bg md:h-24 md:pl-20">
        <div className="flex items-center justify-between py-4 mx-auto">
          <Link to="/">
            <img src={logo} alt="logo" className="w-24 md:w-auto " />
          </Link>
          <div className="items-center justify-end gap-5 pr-5 md:flex">
            <Button
              variant="outline"
              onClick={handleSubmit}
              className="font-DosisMedium px-4 py-1 text-base text-zinc-800 rounded-[40px]"
              style={{ border: "1px solid rgb(39, 39, 42)" }}
            >
              <FontAwesomeIcon icon={faCalendarAlt} />
              &nbsp;&nbsp;Book Now
            </Button>
          </div>
        </div>
      </nav>
    </>
  );
}
