import React from "react";
import Slider from "react-slick";

import passage from "images/homeslider/passage.jpg";
import oval from "images/homeslider/oval.jpg";
import lobby from "images/homeslider/lobby.jpg";
import restaurant from "images/homeslider/restaurant.jpg";

const slides = [
  {
    label: "passage",
    imgPath: passage,
    title: "Welcome to Hotel Yalana, Shillong",
  },
  {
    label: "lobby",
    imgPath: lobby,
    title: "Welcome to Hotel Yalana, Shillong",
  },
  {
    label: "restaurant",
    imgPath: restaurant,
    title: "Welcome to Hotel Yalana, Shillong",
  },
  {
    label: "oval",
    imgPath: oval,
    title: "Welcome to Hotel Yalana, Shillong",
  },
];
//add css style  to image-container class

const MainSlider = () => {
  const settings = {
    dots: false,
    infinite: true,
    autoplaySpeed: 4000,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: false,
    autoplay: true,
    fade: true,
  };

  return (
    <div className="image-container  h-full w-full md:h-[680px]">
      <Slider {...settings}>
        {slides.map((slide, i) => (
          <React.Fragment key={i}>
            <img
              src={slide.imgPath}
              alt={slide.label}
              className="object-cover w-full h-full"
            />
            <div className="hidden sm:block caption">
              <h5 className="mb-2 text-xl font-bold">{slide.title}</h5>
            </div>
          </React.Fragment>
        ))}
      </Slider>
    </div>
  );
};

export default MainSlider;
