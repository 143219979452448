import { Dialog, DialogContent } from "components/ui/dialog";
import Slider from "react-slick";
import { useGetImages } from "hooks/useGetImages";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Button } from "components/ui/button";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { cn } from "lib/utils";
import { ImageWithLoader } from "components/image-with-loading";
interface Props {
  open: boolean;
  onClose: () => void;
  id: number;
}
const SlideButton = ({
  onClick,
  className,
  icon,
}: {
  onClick?: () => void;
  className?: string;
  icon: React.ReactNode;
}) => {
  return (
    <Button
      variant={"secondary"}
      className={cn("", className)}
      size={"icon"}
      onClick={onClick}
    >
      {icon}
    </Button>
  );
};
export const ImageGalleryModal = ({ open, onClose, id }: Props) => {
  const { images, loading } = useGetImages();
  const [imageUrls, setImageUrls] = useState<any[]>([]);

  let sliderRef = useRef(null);
  const next = () => {
    // @ts-ignore
    sliderRef.current?.slickNext();
  };
  const prev = () => {
    // @ts-ignore
    sliderRef.current?.slickPrev();
  };

  const getImageUrls = useCallback(
    (id: number) => {
      const urls = images.filter((img) => img.room_id === id);
      setImageUrls(urls);
    },
    [images]
  );

  useEffect(() => {
    getImageUrls(id);
  }, [getImageUrls, id]);

  const settings = useMemo(
    () => ({
      dots: false,
      infinite: true,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: false,
      accessibility: true,
      autoplay: false,
      swipe: true,
      fade: true,
      arrows: false,
    }),
    []
  );

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="md:max-w-[1280px]  flex justify-center items-center">
        <div className="flex items-center justify-between w-full h-full space-x-1">
          {imageUrls.length > 1 && (
            <div className="flex-shrink-0">
              <SlideButton icon={<ChevronLeft />} onClick={prev} />
            </div>
          )}
          <div className="flex-1 w-0 p-1 md:p-3">
            <Slider
              ref={sliderRef}
              slide="div"
              {...settings}
              className={`w-full h-full `}
            >
              {imageUrls &&
                imageUrls.map((img, i) => (
                  <ImageWithLoader
                    key={i}
                    loading={loading}
                    src={img.image}
                    alt={img.alt_text ?? "viewed image gallery"}
                    className="aspect-square md:aspect-video"
                  />
                ))}
            </Slider>
          </div>
          {imageUrls.length > 1 && (
            <div className="flex-shrink-0">
              <SlideButton icon={<ChevronRight />} onClick={next} />
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};
