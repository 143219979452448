import { z } from "zod";

export const FeedBackModel = z.object({
  name: z
    .string({
      message: "Name is required",
    })
    .min(3, {
      message: "Name must be atleast 3 characters long",
    })
    .max(20, {
      message: "Name must be atmost 20 characters long",
    })
    .trim()
    .regex(/^[a-zA-Z ]+$/, {
      message: "Name must contain only alphabets",
    }),
  rating: z
    .string({
      message: "Rating is required",
    })
    .min(1, {
      message: "Rating must be atleast 1 character long",
    })
    .max(1, {
      message: "Rating must be atmost 1 character long",
    }),
  review: z
    .string({
      message: "Review is required",
    })
    .min(20, {
      message: "Review must be atleast 20 characters long",
    })
    .max(200, {
      message: "Review must be atmost 200 characters long",
    }),
});

export type FeedBackModelType = z.infer<typeof FeedBackModel>;
