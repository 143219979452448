import { FormFieldType } from "components/types";

export const reviewFields: FormFieldType[] = [
  {
    type: "text",
    name: "name",
    label: "Name",
    placeholder: "Your Name",
    required: true,
  },
  {
    type: "text",
    name: "rating",
    label: "Rating",
    placeholder: "Please rate us from 1 to 5 stars",
    required: true,
    radio: true,
    horizontal: true,
    options: [
      {
        label: "1",
        value: "1",
      },
      {
        label: "2",
        value: "2",
      },
      {
        label: "3",
        value: "3",
      },
      {
        label: "4",
        value: "4",
      },
      {
        label: "5",
        value: "5",
      },
    ],
  },
  {
    type: "textarea",
    name: "review",
    label: "Review",
    placeholder: "Your Review",
    required: true,
  },
];
