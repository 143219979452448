import BookRooms from "components/book-room/book-rooms";
import Home from "components/homepage/Home";
import { Toaster } from "components/ui/toaster";
import { Routes, Route } from "react-router-dom";
export default function Router() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/bookroom" element={<BookRooms />} />
        <Route path="*" element={<Home />} />
      </Routes>
      <Toaster />
    </>
  );
}
