import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { RoomBookingModel, RoomBookingType } from "Model/roombooking";
import { FormTag, YalanaForm } from "../form";
import { bookRoomFormFields } from "./book-room-form-fields";
import { RoomImage } from "./room-image";
import { Card } from "components/ui/card";
import { toast } from "components/ui/use-toast";
import { BookingSuccessModel } from "./booking-success-modal";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import { useGetImages } from "hooks/useGetImages";

interface RoomT {
  id: number;
  name: string;
  price: string;
  image: string;
}
export default function BookRooms() {
  const { images } = useGetImages();
  const [open, setOpen] = useState(false);
  const [modalData, setModalData] = useState<RoomBookingType | null>(null);
  const [roomId, setRoomId] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const [rooms, setRooms] = useState<RoomT[]>([]);
  const form = useForm<RoomBookingType>({
    resolver: zodResolver(RoomBookingModel),
    defaultValues: {
      name: "",
      arrival: new Date(),
      departure: new Date(),
      roomType: "",
      roomCount: 1,
      adultCount: 1,
      childrenCount: 0,
      email: "",
      mobile: "",
    },
  });

  // Fetch rooms from API
  const getRooms = useCallback(async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        "https://booking.yalanahotel.com/api/roomcategory"
      );
      setRooms(res.data);
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to fetch rooms",
        variant: "destructive",
      });
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getRooms();
  }, [getRooms]);

  const roomType = form.watch("roomType");

  // Update roomId when roomType changes
  const getRoomIdOnRoomSelectType = useCallback(() => {
    if (roomType) {
      const selectedRoom = rooms.find((room) => room.name === roomType);
      setRoomId(selectedRoom?.id ?? 0);
    }
  }, [roomType, rooms]);

  useEffect(() => {
    getRoomIdOnRoomSelectType();
  }, [getRoomIdOnRoomSelectType]);

  // Update roomType when roomId changes
  const getRoomTypeOnRoomId = useCallback(() => {
    if (roomId) {
      const selectedImage = images?.find((img) => img.room_id === roomId);
      if (selectedImage) {
        form.setValue("roomType", selectedImage.room);
      }
    }
  }, [images, form, roomId]);

  useEffect(() => {
    getRoomTypeOnRoomId();
  }, [getRoomTypeOnRoomId]);

  // Set roomId from search params
  useEffect(() => {
    const idParam = searchParams.get("id");
    if (idParam) {
      setRoomId(Number(idParam));
    }
  }, [searchParams]);

  const onSubmit = async (data: RoomBookingType) => {
    setLoading(true);
    try {
      const api = "https://booking.yalanahotel.com/api/bookroom";
      const response = await axios.post(api, data);
      if (response.data.message === "success") {
        setOpen(true);
        setModalData(data);
      }
    } catch (error: any) {
      toast({
        description: error.message ?? "Something went wrong, try again",
      });
    } finally {
      setLoading(false);
      form.reset();
    }
  };

  // Adding room type to the fields
  const fields = [
    {
      name: "roomType",
      label: "Room Category",
      options: rooms.map((room) => ({
        value: room.name,
        label: room.name,
      })),
      select: true,
      required: true,
    },
    ...bookRoomFormFields,
  ];
  return (
    <>
      <BookingSuccessModel
        open={open}
        data={modalData}
        onClose={() => setOpen(!open)}
      />
      <Card className="container grid h-full grid-cols-12 p-5 mt-5 shadow-md place-content-center">
        <div className="flex flex-col space-y-2 col-span-full">
          <div>
            <h1 className="text-xl text-center font-DosisBold md:text-3xl">
              Hotel Booking
            </h1>
          </div>
          <div>
            <p className="mb-5 text-lg text-center font-InterRegular">
              Experience something new every moment
            </p>
          </div>
        </div>
        <div className="flex w-full col-span-full lg:col-span-6">
          <div className="flex items-center w-full h-full font-InterRegular">
            <RoomImage id={roomId} className="rounded-sm" />
          </div>
        </div>
        <div
          id="bookroom"
          className="flex flex-col justify-center w-full h-full font-InterRegular col-span-full lg:col-span-6"
        >
          <FormTag
            form={form}
            onSubmit={onSubmit}
            isLoading={loading}
            className="p-5 space-y-5 md:p-10"
          >
            <YalanaForm
              form={form}
              loading={loading}
              inputFields={fields}
              className="grid-cols-1 sm:grid-cols-1 xl:grid-cols-2"
            />
          </FormTag>
        </div>
      </Card>
    </>
  );
}
