import { FormFieldType } from "../types";
type OptionT = {
  label: string;
  value: string;
};

export const bookRoomFormFields: FormFieldType[] = [
  {
    name: "name",
    label: "Name",
    required: true,
  },
  {
    name: "email",
    label: "Email",
    required: true,
  },
  {
    name: "mobile",
    label: "Mobile",
    required: true,
  },
  {
    name: "arrival",
    label: "Arrival",
    type: "date",
  },
  {
    name: "departure",
    label: "Departure",
    type: "date",
  },
  {
    name: "roomCount",
    label: "No of Rooms",
    type: "number",
  },
  {
    name: "adultCount",
    label: "Adults",
    type: "number",
  },
  {
    name: "childrenCount",
    label: "Children",
    type: "number",
  },
];
