import { cn } from "lib/utils";
import { useEffect, useState } from "react";
import { Skeleton } from "./ui/skeleton";

interface Props {
  src: string | undefined;
  alt?: string;
  className?: string;
  loading?: boolean;
}

export const ImageWithLoader = ({ src, alt, className, loading }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(loading || true);
  const styles = cn(
    "object-cover object-center w-full h-full rounded-lg transition-all duration-300",
    className,
    isLoading ? "blur-lg p-2 " : "blur-0"
  );

  useEffect(() => {
    setIsLoading(true);
  }, [src]);

  const handleImageLoad = () => {
    setIsLoading(false);
  };

  return (
    <div className="relative w-full h-full ">
      {isLoading && (
        <Skeleton className="absolute inset-0 w-full h-full min-w-full min-h-full rounded-lg ">
          <div className="w-full h-full p-2 bg-gray-300 rounded-lg animate-pulse" />
        </Skeleton>
      )}
      <img
        src={src}
        alt={alt || "image"}
        className={styles}
        onLoad={handleImageLoad}
        onError={handleImageLoad} // Optionally handle errors to hide loader
      />
    </div>
  );
};
