import React from "react";
import MainSlider from "./MainSlider";
function HeroSection() {
  return (
    <div>
      <MainSlider />
      <div className="h-full md:h-1/2 pb-10 ">
        <div className="title-style-2">
          <p className="text-center py-5 md:pt-14 text-xl md:text-3xl font-DosisBold">
            HOTEL YALANA
          </p>
          <hr />
        </div>
        <p className="w-full pt-10 px-2 text-left md:text-center text-lg md:text-xl">
          The Hotel Yalana in Laitumkhrah is a unique and affordable hotel in
          Shillong. <br />
          Hotel Yalana has two Conference Halls, an excellent restaurant, the De
          Oval Bar and spacious parking space. <br /> Located in the heart of
          Shillong, Hotel Yalana has more than 36 standard, family and deluxe
          rooms for your comfort
        </p>
      </div>
    </div>
  );
}

export default HeroSection;
