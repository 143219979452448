import axios from "axios";
import { toast } from "components/ui/use-toast";
import { useEffect, useState, useCallback, useMemo } from "react";

interface Props {
  url?: string;
  filter?: boolean;
  roomType?: string;
  prefetch?: boolean;
}

interface ImagesType {
  id: number;
  room_id: number;
  image: string;
  alt_text: null;
  title: null;
  room: string;
  show_in_bookroom: string;
  rate: number;
}

export const useGetImages = ({
  url,
  roomType,
  filter,
  prefetch: runOnMount = true,
}: Props = {}) => {
  const [images, setImages] = useState<ImagesType[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const apiUrl = useMemo(
    () => url || process.env.REACT_APP_PUBLIC_ROOMS_IMAGES_URL!,
    [url]
  );

  const getImageFromApi = useCallback(async () => {
    setLoading(true);
    try {
      const res = await axios.get(apiUrl);
      let data: ImagesType[] = [];
      if (Array.isArray(res.data)) {
        data = res.data;
      } else {
        data = [res.data];
      }

      if (filter) {
        data = data.filter((item: ImagesType) => item.room === roomType);
      }

      setImages(data);
    } catch (error: any) {
      toast({
        title: "Error",
        description: error.message ?? "Something went wrong",
      });
    } finally {
      setLoading(false);
    }
  }, [apiUrl, filter, roomType]);

  useEffect(() => {
    if (runOnMount) {
      getImageFromApi();
    }
  }, [getImageFromApi, runOnMount]);

  const refetch = useCallback(() => {
    getImageFromApi();
  }, [getImageFromApi]);

  return { images, loading, refetch };
};
