import { buttonVariants } from "components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
} from "components/ui/dialog";
import { RoomBookingType } from "Model/roombooking";
import { Link } from "react-router-dom";

type Props = {
  open: boolean;
  onClose: () => void;
  data?: RoomBookingType | null;
};
export const BookingSuccessModel = ({ open, onClose, data }: Props) => {
  const gridSpan1 = "col-span-4";
  const gridSpan2 = "col-span-8";
  return (
    <Dialog onOpenChange={onClose} open={open}>
      <DialogContent className="text-start">
        <DialogHeader className={"text-xl font-semibold"}>
          Booking Successful
        </DialogHeader>
        <DialogDescription>
          Your booking was successful, we will get back to you soon.
        </DialogDescription>
        {data && data.name && (
          <div className="grid items-start grid-cols-12 mt-5 space-y-2 font-medium capitalize text-md">
            <div className={gridSpan1}>
              <p>Name:</p>
            </div>
            <div className={gridSpan2}>
              <p>{data?.name}</p>
            </div>
            <div className={gridSpan1}>
              <p>Email:</p>
            </div>
            <div className={gridSpan2}>
              <span>{data?.email}</span>
            </div>
            <div className={gridSpan1}>
              <p>mobile: </p>
            </div>
            <div className={gridSpan2}>
              <p>{data?.mobile}</p>
            </div>

            <div className={gridSpan1}>
              <p>room Type:</p>
            </div>
            <div className={gridSpan2}>
              <p>{data?.roomType.toLowerCase()}</p>
            </div>
            <div className={gridSpan1}>
              <p>arrival: </p>
            </div>
            <div className={gridSpan2}>
              <p>{data?.arrival.toLocaleDateString()}</p>
            </div>

            <div className={gridSpan1}>
              <p>departure:</p>
            </div>
            <div className={gridSpan2}>
              <p>{data?.departure.toLocaleDateString()}</p>
            </div>
            <div className={gridSpan1}>
              <p>room Count:</p>
            </div>
            <div className={gridSpan2}>
              <p> {data?.roomCount}</p>
            </div>
            <div className={gridSpan1}>
              <p>adult Count: </p>
            </div>
            <div className={gridSpan2}>
              <p> {data?.adultCount}</p>
            </div>
            <div className={gridSpan1}>
              <p>children Count:</p>
            </div>
            <div className={gridSpan2}>
              <p> {data?.childrenCount}</p>
            </div>
          </div>
        )}
        <DialogFooter className="flex items-center justify-end">
          <Link to={"/"} className={buttonVariants()}>
            Go Home
          </Link>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
