import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faWifi,
  faLock,
  faParking,
  faWineGlass,
  faUtensils,
  faCar,
  faHandshake,
} from "@fortawesome/free-solid-svg-icons";
import Slider from "react-slick";
import locker from "images/facilities/locker.jpg";
import oval from "images/facilities/oval.jpg";
import parking from "images/facilities/parking.jpg";
import restaurant from "images/facilities/restaurant.jpg";

const FacilityContent = ({ title }: { title: string }) => {
  return <span className="text-lg md:text-2xl font-InterRegular">{title}</span>;
};
export default function Facilities() {
  const slides = [
    {
      imgPath: locker,
      title: "Locker Facility",
    },
    {
      imgPath: oval,
      title: "De Oval Bar",
    },
    {
      imgPath: parking,
      title: "Parking Space",
    },
    {
      imgPath: restaurant,
      title: "Restaurant",
    },
  ];
  const settings = {
    dots: false,
    infinite: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    fade: true,
    autoplaySpeed: 3000,
  };
  return (
    <div className="px-2 md:px-44 h-screen">
      <div className="title-style-2 py-5 md:py-20">
        <p className="font-DosisBold text-center text-xl md:text-3xl ">
          OUR SERVICES
        </p>
        <p className="text-center pt-3 font-InterRegular text-lg">
          For your convenience
        </p>
        <hr />
      </div>
      <div className="md:grid md:grid-flow-col md:grid-cols-3 mt-5 md:mt-10">
        <ul className="col-span-1 list-none p-2 md:pl-8">
          <li>
            <span>
              <FontAwesomeIcon icon={faWifi} className="mr-4" />
              <FacilityContent title="Free Wifi" />
            </span>
          </li>
          <li>
            <span>
              <FontAwesomeIcon icon={faLock} className="mr-5" />{" "}
              <FacilityContent title="Locker Facility" />
            </span>
          </li>
          <li>
            <span>
              <FontAwesomeIcon icon={faHandshake} className="mr-5" />
              <FacilityContent title="Conference Hall" />
            </span>
          </li>
          <li>
            <span>
              <FontAwesomeIcon icon={faParking} className="mr-5" />
              <FacilityContent title="Parking Space" />
            </span>
          </li>
          <li>
            <span>
              <FontAwesomeIcon icon={faWineGlass} className="mr-5" />
              <FacilityContent title="De Oval Bar" />
            </span>
          </li>
          <li>
            <span>
              <FontAwesomeIcon icon={faUtensils} className="mr-5" />
              <FacilityContent title="Restaurant" />
            </span>
          </li>
          <li>
            <span>
              <FontAwesomeIcon icon={faCar} className="mr-5" />
              <FacilityContent title="Sight Seeing" />
            </span>
          </li>
        </ul>
        <div className="col-span-2 items-center justify-center ">
          <Slider {...settings}>
            {slides.map((slide) => (
              <div key={slide.title}>
                <img
                  src={slide.imgPath}
                  alt={slide.title}
                  className="h-full md:h-[300px] w-full object-cover"
                />
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
}
